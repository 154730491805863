import { default as env3xwTLHJjLcMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/env.vue?macro=true";
import { default as indexn1orDcvp30Meta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/index.vue?macro=true";
import { default as indexyPp0y5IuKEMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund/index.vue?macro=true";
import { default as pending_45redemptions55HpVBXJSjMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund/pending-redemptions.vue?macro=true";
import { default as insurance_45fundxW9tC19BiRMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund.vue?macro=true";
import { default as maintenancegnKXdGQBGWMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/maintenance.vue?macro=true";
import { default as eligibilityOfmrugYFwZMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/eligibility.vue?macro=true";
import { default as indexBpPBK42QI7Meta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/index.vue?macro=true";
import { default as rewardsQ8FGedOClrMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/rewards.vue?macro=true";
import { default as scoresOLTOIs5DipMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/scores.vue?macro=true";
import { default as snapshotsNm1Y1g6RBUMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/snapshots.vue?macro=true";
import { default as liquidityM7nR4ZvrSzMeta } from "/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: env3xwTLHJjLcMeta || {},
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexn1orDcvp30Meta || {},
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/index.vue")
  },
  {
    name: insurance_45fundxW9tC19BiRMeta?.name,
    path: "/insurance-fund",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund.vue"),
    children: [
  {
    name: "insurance-fund",
    path: "",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund/index.vue")
  },
  {
    name: "insurance-fund-pending-redemptions",
    path: "pending-redemptions",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/insurance-fund/pending-redemptions.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancegnKXdGQBGWMeta || {},
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/maintenance.vue")
  },
  {
    name: liquidityM7nR4ZvrSzMeta?.name,
    path: "/program/liquidity",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity.vue"),
    children: [
  {
    name: "program-liquidity-eligibility",
    path: "eligibility",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/eligibility.vue")
  },
  {
    name: "program-liquidity",
    path: "",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/index.vue")
  },
  {
    name: "program-liquidity-rewards",
    path: "rewards",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/rewards.vue")
  },
  {
    name: "program-liquidity-scores",
    path: "scores",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/scores.vue")
  },
  {
    name: "program-liquidity-snapshots",
    path: "snapshots",
    component: () => import("/home/runner/work/injective-dmm-v2/injective-dmm-v2/pages/program/liquidity/snapshots.vue")
  }
]
  }
]